@import '../../styles/layout/config';
.liquid-viscosity-unit {
  .input-container {
    background: white;
    border-radius: 12px;
    padding: 24px;
    margin-bottom: 16px;
    .title {
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      width: 150px;
      width: unset !important;
    }

    .ant-form-item {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .ant-form-item-no-colon {
      width: 112px;

      label {
        font-size: 16px;
        font-weight: 500;
      }
    }

    .ant-input {
      border-radius: 24px;
      height: 41px;
    }

    .ant-select {
      .ant-select-selector {
        border-radius: 24px;
        height: 41px;

        .ant-select-selection-item {
          line-height: 41px;
        }
      }
    }
    .note {
      font-weight: 700;
      font-size: 14px;
      line-height: 150%;
      // text-transform: capitalize;
    }
    .note1 {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #474b55;
    }
    .unit1 {
      padding: 0 !important;
    }
    form.en {
      .ant-form-item {
        .ant-row {
        }
        &.flex-start {
          .ant-row {
            align-items: flex-start !important;
          }
        }
      }
    }
  }

  .result-container {
    ul > li {
      list-style-type: '-   ';
    }

    .check-box {
      margin-bottom: 12px;
    }

    .judgement,
    .ratio {
      display: inline-block;
      margin-right: 50px;

      input {
        width: 300px;
      }
    }

    .result-table {
      .ant-descriptions-item-label {
        background: #f5f5f5;
        color: #4588c9;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        text-align: center;
        width: 112px;
      }

      .ant-descriptions-item-content {
        padding: 0;
      }
    }

    .input-group {
      margin: 0;
    }
  }

  @include media-md {
    .note {
      margin-bottom: 16px;
    }
    .input-container {
      padding: 16px;
      .title {
        width: unset;
      }
      form.en {
        .ant-form-item {
          &.flex-start {
            .ant-row {
              > .ant-col {
                > label {
                  margin-top: unset;
                }
              }
            }
          }
        }
      }

      .note1 {
        font-size: 12px;
      }
    }
  }
}
