@import '../common/color-variable.scss';
@import '../../styles/layout/config';

.fl-rate-container {
  .input-container {
    .title {
      font-size: 16px;
      font-weight: 700;
      color: $black1;
      // margin-bottom: 16px;
      height: 24px;
      margin-right: 16px;
    }
    .title-result {
      font-size: 16px;
      font-weight: 700;
      color: black;
      align-items: center;
    }
    .unit {
      line-height: 41px;
      font-size: 14px;
      color: $grey1;
      margin-left: 8px;
    }
    .unit1 {
      line-height: 24px;
      font-size: 16px;
      color: $grey1;
    }
    .note {
      margin-top: 16px;
      line-height: 150%;
    }
    .note1 {
      width: 110%;
      display: inline-block;
      height: 24px;
      font-size: 16px;
      color: $grey1;
    }

    .note-vvalue {
      display: inline-block;
      width: 100%;
      font-size: 10px;
      height: 30px;
      margin-top: 8px;
    }
    .notice {
      color: $black2;
      font-size: 16px;
      height: 24px;
    }
    .temp-notice {
      color: $grey1;
      font-size: 16px;
      height: 24px;
    }
    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.85);
    }
    .notice-selector {
      margin-top: 8px;
      font-size: 10px;
      color: $black2;
    }
    .fluid-state .fluid-state-checkbox {
      font-weight: 400;
      font-size: 14px;
      color: $black1;
      min-height: 24px;
      align-items: center;
    }
    .fluid-note {
      margin-top: 16px;
      font-size: 12px;
      color: $grey1;
      height: 24px;
    }
    .input-result {
      font-size: 16px;
      color: #23262f;
      font-style: bold;
      font-family: inherit;
      margin-right: 8px;
    }
    .gfValue {
      margin-bottom: 0;
    }
  }
  .result-row {
    .ant-col {
      display: flex;
      margin-right: 89px;
    }
  }
  .base-form-item .ant-form-item .base-form-note {
    white-space: pre-line;
    color: #474b55;
  }

  .fr-liquid {
    .ant-form-item-no-colon label .form-label {
      width: 133px;
      margin-right: 16px;
    }
    .ant-form-item .ant-form-item-control-input {
      min-height: 24px;
    }
    .ant-form-item {
      margin-bottom: 16px;
    }
    .gfValue {
      margin-bottom: 0;
    }
    .result {
      margin-bottom: 16px;
    }
  }
  .fr-gases {
    .form-label {
      min-width: 153px;
      margin-right: 16px;
    }
    .note1 {
      margin-top: 16px;
      width: 110%;
    }
    .fr-unit {
      margin-bottom: 0;
    }
    .form-item .vvalue-selector {
      margin-bottom: 0;
    }
  }
  .fr-steam {
    .calc-result {
      margin-bottom: 16px;
    }
    .result {
      margin-bottom: 16px;
    }
    .ant-form-item {
      margin-bottom: 16px;
    }
  }
  .radio-btn-title {
    .ant-form-item-no-colon {
      align-items: flex-start;
    }
  }
  @include media-xxl {
    .input-container .note1 #line {
      width: 100%;
    }
  }
  @include media-md {
    .input-container {
      .set-unit {
        padding: unset !important;
      }
      .unit {
        font-size: 14px;
      }
      .unit1 {
        font-size: 12px;
      }
      .temp-notice {
        font-size: 12px;
      }
      .note1 {
        width: 110%;
        font-size: 12px;
        line-height: 150%;
        // height: 18px;
      }
      .result .ant-input {
        width: 320px;
      }
      .fr-liquid {
        .base-form-item {
          margin-bottom: 0;
        }
      }
      .fr-gases {
        .base-form-item {
          margin-bottom: 0;
        }
      }
    }
  }
  @include media-sm {
    .input-container {
      .result .ant-input {
        width: 240px;
      }
      .unit {
        margin-left: 0;
      }
      .notice {
        font-size: 12px;
      }
    }
  }
}
