@import '../../styles/layout/config';
.cv {
  .ant-form-item .ant-form-item-label > label::after {
    margin: 0;
    width: 16px;
  }

  .note1 {
    font-size: 10px;
    line-height: 15px;
    color: #474b55;
  }

  &.liquid {
    .input-container .ant-form-item-no-colon {
      width: 133px;
    }
  }

  &.gas {
    .input-container {
      .base-form .ant-form-item .base-form-note {
        margin-top: 8px;
      }

      .ant-form-item-no-colon {
        width: 153px;
      }
    }
  }

  &.steam {
    .input-container {
      .base-form .ant-form-item .base-form-note {
        margin-top: 8px;
      }

      .ant-form-item-no-colon {
        width: 148px;
      }
    }

    .ant-form-item .ant-form-item-control-input {
      min-height: auto;
    }
  }
}
