@import '../layout/config';
.kitz-header-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 100px;
  height: 80px;
}

.kitz-back {
  display: flex;
}
@include media-md {
  .kitz-header-wrap {
    margin: 0 16px;
  }
  .back-to-home {
    img {
      width: 24px;
    }
    flex-direction: column;
    align-items: center;
  }
}
