@import './../../styles/layout/config';

$input-min-width-pc: calc(320px + 16px) !important;
$input-min-width-mb: calc(240px + 16px) !important;
$form-item-margin-pc: 16px;
$form-item-margin-mb: 12px;

.input-container {
  #primary-title {
    margin-bottom: $form-item-margin-pc;
  }
  .text-default {
    color: #23262f;
  }
  .base-form {
    .base-form-item {
      &:last-child {
        .ant-form-item {
          margin-bottom: 0;
          .ant-input-group {
            .base-form-input {
              margin-bottom: 0;
            }
          }
        }
      }
      .base-form-note {
        font-weight: 400;
        font-size: 10px;
        line-height: 150%;
        color: #545454;
        margin-top: 8px;

        &.base-form-note-group {
          font-size: 16px;
          margin-top: 16px;
        }
      }
      .ant-form-item {
        margin-bottom: $form-item-margin-pc;
        &.input-group {
          .ant-form-item {
            margin-bottom: 0;
          }
        }

        &.margin-top-8-8 {
          .base-form-note {
            margin-top: 8px;
          }
        }
        // .base-form-note {
        //   margin-top: $form-item-margin-pc;
        // }
      }

      .ant-form-item-control-input {
        min-height: 24px;
      }

      .radio-button {
        margin: 0;
        .ant-form-item-label {
          height: 24px !important;
          .ant-form-item-no-colon {
            min-height: 24px;
          }
        }

        .ant-radio-wrapper:nth-child(1) {
          margin-right: 76px;
        }
      }

      .flex.flex-wrap {
        .base-form-note {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
          color: #545454;

          &.base-form-note-group {
            font-size: 16px;
            margin-bottom: 16px;
          }
        }
      }
    }
    .col-md {
      // min width = 320 + padding left & right 8px
      width: 30%;
      // min-width: $input-min-width-pc;
    }

    .result-field {
      border: none;
      color: #23262f;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
    }

    .unit {
      color: #474b55;
      font-size: 14px;
      font-family: 'Noto Sans JP';
      display: flex;
      align-items: center;
      padding: 0 !important;
    }
  }
}

@import './reponsive.scss';
