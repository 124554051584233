@import '../../styles/layout/config';

.result-cv {
  .result {
    color: #23262f;
    font-size: 16px;
    margin-right: 16px;
  }

  .grid-container {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(112px, 112px) minmax(320px, 320px) minmax(127px, 253px) minmax(320px, 320px) minmax(
        63px,
        95px
      );
  }

  .layout-col {
    display: block;
    max-width: 316px;
  }

  background: #ffffff;
  border-radius: 12px;
  padding: 24px;

  .target-of-flow {
    font-family: var(--app-font-name);
    font-weight: 400;
    font-size: 16px;
    color: #545454;
    margin-top: 24px;
    line-height: 24px;
  }

  .inner-diameter-title {
    font-family: 'Noto Sans JP';
    font-weight: 500;
    font-size: 16px;
    color: #474b55;
  }

  .inner-diameter-input {
    width: 320px;
  }

  .cv-value + span {
    font-weight: 400;
    font-size: 14px;
    color: #474b55;
  }

  .ant-form-item {
    margin-bottom: 0;
  }

  .cv-value {
    width: 475px;
    background: #f9f9f9;
    border-radius: 8px;
    padding: 12px 16px;
    margin-right: 16px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .title-cv {
    font-weight: 700;
    font-size: 16px;
    color: #000000;
  }

  .label-value {
    text-align: end;
    padding: 10px 16px;
    font-size: 16px;
    font-weight: 700;
    background: #f5f5f5;
    border-radius: 60px;
    width: 100%;
    height: 41px;
    line-height: 150%;
    color: #23262f;
  }

  .text-end {
    text-align: end;
  }

  .ratio {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 21px;
    color: #474b55;
    font-weight: 400;
    font-size: 14px;
  }

  .error {
    // padding-left: 40px;
    color: #dd2f3e;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
  }

  .input-container {
    padding: 0;
    border-radius: unset;
    margin-bottom: 0;

    .ant-form-item-no-colon {
      width: 112px;
      margin: 0;
    }

    .base-form {
      .col-md {
        width: auto;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

  .ant-form-item .ant-form-item-label > label::after {
    margin: 0;
    width: 16px;
  }

  .form-label {
    width: 96px;
  }

  .err-diameter {
    font-size: 16px;
    line-height: 150%;
    font-weight: 700;
  }

  .err {
    color: #dd2f3e;
  }

  > .ant-row {
    input {
      width: 320px;
    }
  }
}
.mt-6 {
  margin-top: 24px;
}
@include media-l {
  .result-cv {
    .grid-container-m {
      grid-template-columns: minmax(112px, 112px) minmax(320px, 320px);

      .ratio {
        padding: 0;
        justify-content: start;
        margin-top: 12px;
      }

      .inner-diameter {
        margin-top: 12px;
      }
    }

    .grid-container-fast {
      grid-template-columns: minmax(112px, 112px) minmax(320px, 320px) minmax(127px, 253px);
      grid-template-areas:
        'title-cv inner-diameter-input ratio'
        'area area ratio2';

      .title-cv {
        grid-area: title-cv;
      }

      .inner-diameter-input {
        grid-area: inner-diameter-input;
      }

      .ratio.mm-12 {
        grid-area: ratio2;
        margin-top: 12px;
      }

      .area {
        margin-top: 12px;
        grid-area: area;
        justify-self: end;
      }
    }
  }
}

@include media-sm {
  .result-cv {
    padding: 16px;
    .ratio {
      padding: 0 16px 0 8px;
    }
    .target-of-flow {
      font-size: 12px;
      margin-top: 12px;
      line-height: 150%;
    }

    .input-container .base-form .base-form-item {
      .col-md {
        width: 226px;
      }
      .ant-form-item-row {
        .ant-form-item-label {
          flex: 0 1 100%;
          height: 34px;
          padding-bottom: 12px;
          margin-bottom: 0;
        }

        .ant-form-item-control {
          flex: 0 1 285px;
        }
      }

      margin-bottom: 0;
    }

    .grid-container-m {
      grid-template-columns: minmax(320px, 320px);

      .title-cv {
        margin-bottom: 12px;
      }

      .ratio {
        height: 45px;
        margin-top: 0;
      }

      .inner-diameter {
        margin-top: 0;
      }
    }

    .inner-diameter-input {
      width: 240px;
    }
    .grid-container-fast {
      grid-template-columns: max-content auto;
      grid-template-areas:
        'title-cv title-cv '
        'inner-diameter-input ratio'
        'area ratio2';

      .area {
        justify-self: unset;
      }

      .inner-diameter-input {
        margin-top: 12px;
      }

      .ratio {
        margin-top: 12px;
      }
    }

    .input-container .ant-row {
      input {
        width: 240px;
      }
    }
  }

  .en {
    .result-cv {
      .form-label {
        width: auto;
      }

      .ant-form-item-no-colon {
        width: auto;
      }
    }
  }
}
@include media-xxl {
  .result-cv {
    .input-container .col-md {
      width: auto !important;
    }
  }
}
