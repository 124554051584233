@import '../../styles/layout/config';

.temperature-unit {
  .unit-table-container {
    .cell-null {
      display: none;
    }
  }
  .result-container {
    ul > li {
      list-style-type: '-   ';
    }

    .check-box {
      margin-bottom: 12px;
    }

    .result-table {
      .ant-descriptions-item-label {
        background: #f5f5f5;
        color: #4588c9;
        font-size: 16px;
        font-weight: 600;
        padding: 0;
        text-align: center;
        width: 112px;
      }

      .ant-descriptions-item-content {
        padding: 0;
      }
    }

    .input-group {
      margin: 0;
    }

    .unit-table-container {
      .cell-null {
        display: none;
      }
    }
  }

  .input-container {
    background: white;
    border-radius: 12px;
    padding: 24x;
    margin-bottom: 16px;
    .base-form {
      .col-md {
        width: unset;
      }
    }
    .title {
      line-height: 150%;
      display: inline-block;
      font-size: 16px;
      font-weight: 700;
      width: 150px;
      margin-bottom: 16px;
    }

    .ant-form-item {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    .ant-form-item-no-colon {
      width: 48px;

      label {
        font-size: 16px;
        font-weight: 500;
      }
    }

    form.en {
      label.ant-form-item-no-colon {
        width: 134px !important;
      }
    }
    .ant-input {
      border-radius: 24px;
      height: 41px;
    }

    .ant-select {
      .ant-select-selector {
        border-radius: 24px;
        height: 41px !important;

        .ant-select-selection-item {
          line-height: 41px;
        }
      }
    }
  }

  .title-primary {
    &::before {
      background-color: #535c9d;
    }
  }
}
@include media-xxl {
  .temperature-unit .input-container {
    .col-md {
      width: 33.333% !important;
    }
  }
}
@include media-md {
  .temperature-unit {
    .input-container {
      padding: 16px;
      .base-form {
        .col-md {
          width: 30%;
        }
      }
      .title {
        width: unset;
        margin-bottom: 19px;
      }
      .ant-form-item .ant-row.ant-form-item-row .ant-col > label {
        width: 100%;
        min-height: unset;
      }
    }
    .layout-mb {
      flex: 1 1 100%;
      padding-right: 71px !important;
    }

    .ant-form-item-label {
      flex: 0 1 100% !important;
      white-space: initial;
      text-align: start;
      display: block;
      max-width: 100%;
    }

    .ant-form-horizontal .ant-form-item-control {
      flex: 1 1 10px !important;
      min-width: 0;
    }
  }
}
