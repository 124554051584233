@import '../../styles/common/color-variable.scss';
@import '../../styles/layout/config';
.title-primary {
  position: relative;
  padding-left: 22px;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  color: $black1;
  justify-content: space-between;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 12px;
    height: 100%;
    border-radius: 2px;
  }
  &.green {
    &::before {
      background-color: $green;
    }
  }
  &.red {
    &::before {
      background-color: $red;
    }
  }
  &.blue {
    &::before {
      background-color: $blue;
    }
  }
  &.yellow {
    &::before {
      background-color: $yellow;
    }
  }
  &.navy {
    &::before {
      background-color: #285fa0;
    }
  }
  &.jade {
    &::before {
      background-color: #54a1be;
    }
  }
  &.pear {
    &::before {
      background-color: #65a27a;
    }
  }
  &.san-mario {
    &::before {
      background-color: #4373a7;
    }
  }
  &.pt {
    &::before {
      background-color: #535c9d;
    }
  }
}

.date {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #4588c9;
}

.title-second {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $black1;
  margin: 0;
  white-space: pre-wrap;
  text-align: left;
  &.small {
    font-size: 16px;
  }
  &.medium {
    font-size: 24px;
  }
  &.mb-16 {
    margin-bottom: 16px;
  }
  &.mb-24 {
    margin-bottom: 24px;
  }
}
$small: 300px;
$medium: 842px;

@include media-md {
  .title-primary {
    font-size: 20px;
    &::before {
      width: 8px;
    }
  }
  .date {
    font-size: 12px;
    margin-right: 0;
  }
  .en {
    .title-primary {
      font-size: 16px;
      line-height: 32px;
    }
    .date {
      display: flex;
      justify-content: flex-end;
      width: 170px;
    }
  }
}
