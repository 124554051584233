// pc >=1441px
@mixin media-xxl {
  @media screen and (min-width: 1441px) {
    @content;
  }
}

// pc <=1440px
@mixin media-xl {
  @media screen and (max-width: 1440px) {
    @content;
  }
}

// mobile and tablet
@mixin media-md {
  @media screen and (max-width: 842px) {
    @content;
  }
}

// tablet
@mixin media-tablet {
  @media screen and (min-width: 600px) and (max-width: 842px) {
    @content;
  }
}

//mobile
@mixin media-sm {
  @media screen and (max-width: 599px) {
    @content;
  }
}

// pc <=1170px
@mixin media-l {
  @media screen and (max-width: 1170px) {
    @content;
  }
}
