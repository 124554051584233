/* stylelint-disable-next-line scss/at-import-partial-extension */
@import '../styles/common/color-variable.scss';
@import '../styles/layout/config';
:root {
  --app-font-name: 'Noto Sans JP';
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  font-family: var(--app-font-name);
}

html,
body,
#app,
#app > div {
  height: 100%;
}

.kitz-header-wrap {
  a {
    text-decoration: none;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
  }

  .kitz-logo-wrap {
    .kitz-logo {
      height: 27px;
      width: 113px;
    }

    > p {
      font-weight: 700;
      font-size: 16px;
      color: $brand-secondary;
      margin: 0;
    }
  }

  .kitz-back {
    font-size: 16px;
    color: $brand-secondary;
    font-weight: 700;
    line-height: 150%;
    display: flex;
    justify-content: flex-end;

    > img {
      margin-right: 18.5px;
    }
  }
}

.page-title {
  padding: 32px 24px;

  span {
    border-radius: 2px;
    font-size: 28px;
    font-weight: 700;
  }
}

.page-container {
  background: #f9f9f9;
  min-height: calc(100vh - 80px);
  padding: 0 100px;
  .highlight-input {
    color: #dd2f3e !important;
  }
}

.hidden-overflow {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
}

.input-container {
  background: $white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;

  .ant-form-item-no-colon {
    width: 160px;
    margin-right: 16px;

    label {
      color: #474b55;
      &::after {
        margin: 0;
      }
      color: $grey1;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .ant-form-item {
    display: flex;
    // margin-bottom: 16px;

    // &:last-child {
    //   margin-bottom: 0;
    // }
    .ant-col.ant-form-item-label {
      // height: 41px;
      .form-label {
        line-height: 150%;
      }
    }
    .ant-row {
      &.ant-form-item-row {
        flex-grow: 1;

        .ant-col {
          > label {
            min-height: 41px;
            height: unset;
            text-align: start;
          }

          input.input-result {
            background-color: #f5f5f5;
            pointer-events: none;
            text-align: right;
            border: unset;
            font-size: 16px;
            font-weight: 700;
          }

          input.input-text-error {
            border-color: $text-danger;
            text-align: right;
          }

          input.input-text {
            text-align: right;
            font-family: 'Noto Sans JP';
          }

          input::placeholder {
            text-align: left;
          }
        }
      }
    }

    .ant-form-item {
      margin-bottom: 0;
    }
  }

  .ant-form-item.label-position-top {
    .ant-row {
      .ant-col {
        > label,
        h2 {
          margin: 0;
          align-items: flex-start;
        }
      }
    }
  }

  .ant-form-item.label-position-center {
    .ant-row {
      .ant-col {
        > label,
        h2 {
          margin: 0;
          align-items: center;
        }
      }
    }
  }

  .hidden-overflow {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
  }

  .ant-input[disabled] {
    background-color: rgba(240, 240, 240, 1);
  }
}
.ant-input {
  min-width: 320px;
  border-radius: 60px;
  height: 41px;
  &::placeholder {
    color: #a9a9a9;
    font-size: 14px;
    line-height: 150%;
  }
}

.ant-select {
  .ant-select-selector {
    min-width: 320px;
    border-radius: 24px;
    height: 41px !important;

    .ant-select-selection-item {
      color: rgba(0, 0, 0, 0.85);
      line-height: 39px;
    }
  }
  .ant-select-arrow {
    .anticon {
      transform: scale3d(1.5, 1.3, 1);
    }
    .ant-select-suffix {
      svg {
        color: #a3a3a3;
      }
    }
  }
}

.error-message {
  font-weight: 700;
  font-size: 16px !important;
  line-height: 150%;
  // text-transform: capitalize;
  color: $text-danger;
}

.result-container {
  background: $white;
  border-radius: 12px;
  padding: 24px;

  .title {
    color: #23262f;
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    margin-right: 16px;
    // width: 160px;
    line-height: 150%;
  }

  .error-message {
    display: inline-block;
    color: #dd2f3e;
    font-weight: bold;
  }

  .result-table {
    .ant-descriptions-item-label {
      background: #f5f5f5;
      color: #4588c9;
      font-size: 16px;
      font-weight: 600;
      padding: 0;
      text-align: center;
      width: 112px;
    }

    .ant-descriptions-item-content {
      padding: 0;
    }
  }

  .highlight-input {
    color: #dd2f3e !important;
  }
}

.note {
  display: inline-block;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;

  span.text-danger {
    color: $text-danger;
  }

  &.note--size-big {
    font-size: 14px;
    font-weight: 700;
  }
}

.content-position {
  display: flex;

  &.top {
    align-items: flex-start;
  }

  &.center {
    align-items: center;
  }
}

.ant-tabs {
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: flex-end;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
  }
}

.ant-form-item-control {
  .ant-form-item-control-input {
    & + * {
      border: red;
      display: none !important;
    }
  }
}
.ant-radio-wrapper {
  .ant-radio-inner::after {
    width: 26px;
    height: 26px;
    transform: scale(0.538) !important;
    background-color: #4588c9;
    inset-block-start: auto;
    inset-inline-start: auto;
    top: 4px;
    left: 4px;
  }
  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }
  .ant-radio-checked {
    .ant-radio-inner {
      border-color: #4588c9;
      background-color: white;
      width: 20px;
      height: 20px;
    }
  }
}
.ant-select-open {
  .ant-select-arrow {
    transform: rotate(180deg);
  }
}
@media-xxl {
  .input-container {
    .col-md {
      width: 33.333% !important;
      height: 41px;
    }
  }
}

/* stylelint-disable-next-line at-rule-no-unknown */
@include media-md {
  .mobile-disable {
    display: none !important;
  }
  .page-container {
    background: #f9f9f9;
    height: calc(100% - 72px);
    padding: 0 16px;
  }
  .kitz-header-wrap .kitz-back {
    font-size: 14px;
    > img {
      margin-right: unset;
    }
  }
  .input-container {
    > .title {
      line-height: 150% !important;
      margin-bottom: 20px;
      width: 100%;
    }
    padding: 16px;
    margin-bottom: 16px;
    .ant-form {
      .ant-form-item {
        .ant-form-item-label {
          padding: 0;
          margin-bottom: 12px;
        }
      }

      // > .ant-form-item {
      // margin-bottom: 20px !important;
      // }

      .ant-form-item {
        .ant-row.ant-form-item-row {
          .ant-form-item-label + .ant-form-item-control {
            margin-bottom: 0;
          }
          .ant-col {
            > label {
              height: fit-content;
            }
          }
        }
      }

      > .ant-form-item:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .ant-input {
    min-width: 240px;
    border-radius: 60px;
    height: 41px;
  }

  .ant-select {
    .ant-select-selector {
      min-width: 240px;
      border-radius: 24px;
      height: 41px !important;

      .ant-select-selection-item {
        line-height: 36px;
      }
    }
    .ant-select-arrow {
      .anticon {
        transform: scale3d(1.5, 1.3, 1);
      }
      .ant-select-suffix {
        svg {
          color: #a3a3a3;
        }
      }
    }
  }
  .ant-table-tbody tr.ant-table-row td.ant-table-cell.text-tr {
    padding: 16px 8px !important;
    #line {
      font-size: 12px !important;
    }
  }
  .result-container {
    padding: 16px;

    .ant-table-tbody tr.ant-table-row td.ant-table-cell.text-tr {
      padding: 16px 8px !important;
      font-size: 12px !important;
    }
    .result-table {
      .unit-table-container {
        .value-switching {
          margin: 12px 0 24px;
        }
      }
    }
  }
}

/* stylelint-disable-next-line at-rule-no-unknown */
@include media-tablet {
  .input-container {
    .ant-form {
      .ant-form-item {
        .ant-form-item-row {
          .ant-form-item-label + .ant-form-item-control {
            margin-bottom: 0;

            .ant-form-item-control-input {
              .ant-form-item-control-input-content {
                .ant-row {
                  .ant-col + .ant-col {
                    .ant-form-item-row {
                      > .ant-col.ant-form-item-control {
                        margin-top: 0px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@include media-sm {
  .input-container {
    .unit {
      margin-left: unset;
    }
    .ant-form {
      .ant-form-item {
        .ant-row.ant-form-item-row {
          .ant-col {
            > label {
              min-height: unset;
            }
          }
        }
      }
    }
  }
}
.en {
  .ant-row .ant-col .form-label {
    font-size: 14px;
    white-space: break-spaces;
    text-align: start;
  }

  // .result-container .title {
  //   width: 200px;
  // }

  @include media-sm {
    .input-container {
      .ant-form-item {
        .ant-col.ant-form-item-label {
          .ant-form-item-no-colon {
            width: 100%;
          }
          label {
            width: 100%;
          }
        }
      }
    }
  }
}

#root .ant-select .ant-select-arrow {
  right: 20px;
  inset-inline-start: auto;
}

.unit {
  line-height: 150%;
  font-size: 14px;
  font-family: 'Noto Sans JP';
  margin-left: 8px;
}

.ant-input {
  padding: 10px 16px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 16px;
}

// text style for label under radiogroup
.text-under-radiogroup {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  margin: 16px 0 0;
}

// .ant-radio-wrapper {
//   margin-bottom: 16px;
// }

.label-small {
  font-size: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
}

@include media-sm {
  .text-under-radiogroup {
    font-size: 12px;
  }
}

.gas {
  .input-container .ant-form-item-no-colon {
    width: 153px;
  }
}

.liquid {
  .input-container .ant-form-item-no-colon {
    width: 133px;
  }
}

.steam {
  .input-container .ant-form-item-no-colon {
    width: 148px;
  }
}
