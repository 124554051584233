@import '../../styles/layout/config';
.velocity-to-flow-rate {
  .result {
    background: #fff;
    border-radius: 12px;
    padding: 24px;

    .error {
      // padding-left: 40px;
      color: #dd2f3e;
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
    }
  }

  .note-radio {
    // margin-top: 16px;
    font-size: 16px;
    line-height: 150%;
    color: #474b55;
  }

  .ant-form-item .ant-form-item-label > label::after {
    margin: 0;
    width: 16px;
  }
  .font-bold {
    margin-right: 16px;
    font-size: 16px;
    color: #23262f;
  }
  &.steam {
    .input-container {
      .ant-form-item-no-colon {
        width: 148px;
      }
      .ant-form .base-form-item:last-child {
        .base-form-note {
          margin-bottom: unset;
        }
      }
    }
  }

  &.gas {
    .input-container .ant-form-item-no-colon {
      width: 153px;
    }
    .unit-table-container .des {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .note2 {
    color: #474b55;
    font-size: 16px;
    line-height: 150%;
  }

  .note1 {
    margin-top: -8px;
  }
}

@include media-sm {
  .velocity-to-flow-rate {
    &.steam {
      .note-radio {
        padding-left: 0;
      }
    }
    .note-radio {
      font-size: 12px;
      margin-top: 12px;
    }

    .result {
      padding: 16px;
    }
  }
}
