.value-switching {
  margin: 16px 0;

  .ant-select {
    margin-right: 10px;

    .ant-select-selector {
      width: 100px;
    }
  }

  .ant-radio-wrapper {
    margin-inline-end: 16px;
    color: #23262f;
    font-weight: 400;
    line-height: 150%;
  }
}
