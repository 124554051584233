.container-calculate-liquid-cv {
  background-color: #f9f9f9;
  padding: 0 100px;

  .calculate-liquid-cv {
    // padding: 0 16px;
    // max-width: 1272px;
    margin: 0 auto;
    font-size: 16px;
    padding-bottom: 40px;

    .h-24 {
      height: 24px !important;
    }

    .err-diameter-container {
      height: 24px;

      .err-diameter {
        color: #dd2f3e;
        font-size: 13px;
        font-weight: 700;
      }
    }

    .grid-container {
      display: grid;
      grid-template-columns: minmax(100px, 341px) minmax(95px, 253px) minmax(100px, 341px) minmax(63px, 95px);
    }

    .flex {
      display: flex;
      align-items: center;
    }

    .ml-16 {
      margin-left: 16px;
    }

    .rectangle {
      border-radius: 2px;
      margin-right: 10px;
      width: 12px;
      height: 40px;
    }

    .rectangle-blue {
      background: #57aeff;
    }

    .rectangle-yellow {
      background: #ffd057;
    }

    .rectangle-red {
      background: #ff5757;
    }

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 28px;
      line-height: 150%;
      color: #23262f;
      margin-bottom: 0;
      margin-top: 0;
    }

    .text-center {
      text-align: center;
    }

    .title-table {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #f5f5f5;
      color: #4588c9;
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
    }

    .ant-table-container table > thead > tr:first-child th:first-child {
      border-start-start-radius: 0;
    }

    .ant-table-container table > thead > tr:first-child th:last-child {
      border-start-end-radius: 0;
    }

    .mr-76 {
      margin-right: 76px;
    }

    .input-calculate {
      .layout-col {
        display: block;
        max-width: 316px;
      }

      background: #ffffff;
      border-radius: 12px;
      padding: 24px;

      .speed {
        font-weight: 400;
        font-size: 11px;
        color: #474b55;
        margin-top: 8px;
        margin-bottom: 0;
      }

      .mt-24 {
        margin-top: 24px;
      }

      .custom-border {
        border: 1px solid #a3a3a3;
        border-radius: 60px;
      }

      .label {
        font-family: 'Noto Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #474b55;
        height: 41px;
        display: flex;
        align-items: center;
      }

      .input {
        height: 36px;
      }

      .mt-16 {
        margin-top: 16px;
      }

      .mb-16 {
        margin-bottom: 16px;
      }

      .flex b {
        margin-right: 16px;
      }

      .justify-content-center {
        justify-content: center;
      }

      .align-items-center {
        align-items: center;
      }

      .lh-24 {
        line-height: 24px;
      }

      .inner-diameter-title {
        font-family: 'Noto Sans JP';
        font-weight: 500;
        font-size: 16px;
        color: #474b55;
      }

      .text-red {
        color: red;
      }

      .inner-diameter-input {
        max-width: 341px;
      }

      // span {
      //   font-weight: 400;
      //   font-size: 14px;
      //   color: #474b55;
      //   margin-left: 16px;
      // }

      .cv-value + span {
        font-weight: 400;
        font-size: 14px;
        color: #474b55;
      }

      .flex.lh-24 {
        margin-top: 24px;

        span {
          font-weight: 400;
          font-size: 14px;
          color: #474b55;
        }
      }

      .ant-form-item {
        margin-bottom: 0;
      }

      .cv-value {
        width: 475px;
        background: #f9f9f9;
        border-radius: 8px;
        padding: 12px 16px;
        margin-right: 16px;
      }

      .rate-flow {
        margin-left: 16px;
        margin-right: 135px;
      }

      h4 {
        font-size: 16px;
        margin-bottom: 0px;
        margin-top: 0;
        color: #23262f;
        line-height: 150%;
      }

      .mb-24 {
        margin-bottom: 24px;
      }

      .w-341 {
        width: 341px;
      }

      .title-cv {
        font-weight: 700;
        font-size: 16px;
      }

      .dir {
        color: #474b55;
        font-weight: 400;
        font-size: 12px;
        margin-top: 8px;
      }

      .di2 {
        color: #23262f;
        font-weight: 400;
        font-size: 12px;
        margin-top: 8px;
      }

      .fs-12 {
        font-size: 12px;
        line-height: 150%;
      }

      .note {
        font-size: 16px;
        font-family: 'Noto Sans JP';
        color: #474b55;
        padding: 0 16px !important;
      }

      .label-value {
        text-align: end;
        padding: 10px 16px;
        line-height: 21px;
        font-size: 16px;
        font-weight: bold;
        background: #f5f5f5;
        border-radius: 60px;
        width: 100%;
        height: 41px;
      }

      .text-end {
        text-align: end;
      }

      .ratio {
        display: flex;
        align-items: center;
        padding: 0 16px;
        height: 41px;
        color: #474b55;
        font-weight: 400;
        font-size: 14px;
      }

      .error {
        padding-left: 40px;
        color: #dd2f3e;
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
      }

      @media (max-width: 842px) {
        .dir {
          font-size: 10px;
          margin-left: 16px;
        }
        .label {
          flex: 1 1 100% !important;
        }

        .title-cv {
          flex: 0 1 100% !important;
        }

        .inner-diameter-title {
          flex: 0 1 100% !important;
        }

        .layout-col {
          max-width: 100%;
        }

        .none {
          display: none;
        }

        .pr-63 {
          padding-right: 79px !important;
        }

        .unit {
          padding-left: 0px !important;
          flex: 0 1 71px;
        }

        .des {
          font-size: 12px;
          margin-bottom: 24px;
        }

        .dir2 {
          font-size: 10px;
          color: #23262f;
        }
        .note {
          font-size: 12px;
          margin-top: 8px;
        }
        .des {
          font-size: 12px;
        }

        .ant-row {
          row-gap: 12px !important;
        }

        .unit-table-container {
          .ant-row {
            row-gap: 0px !important;
          }
        }

        .grid-container {
          grid-template-columns: minmax(100px, 100%) 71px;

          .inner-diameter-input {
            max-width: 100%;
          }

          .mm-12 {
            margin-top: 12px;
          }
        }

        .grid-container.grid-container-m {
          .inner-diameter-input {
            margin-right: 71px;
          }

          .text-end {
            text-align: start;
            padding: 12px 0;
            line-height: 21px;
          }
        }

        .des-mb {
          row-gap: 0 !important;
        }
      }
    }

    .input-calculate + .input-calculate {
      margin-top: 16px;
    }

    .mw-240 {
      max-width: 240px;
    }

    .unit {
      font-family: 'Noto Sans JP';
      font-weight: 400;
      font-size: 14px;
      color: #474b55;
      line-height: 41px;
    }

    .ant-input {
      padding: 10px 16px;
      height: 41px;
      font-family: 'Noto Sans JP';
    }

    .ant-select-selector {
      padding: 0 20px 0 16px;
    }

    .ant-select-arrow {
      inset-inline-end: 20px;
    }

    .ant-select-selector {
      height: 41px !important;
    }

    .ant-input::placeholder {
      text-align: start;
      color: #a9a9a9;
    }

    h1.title-primary {
      color: #23262f;
      margin-left: 0;
    }

    input {
      text-align: end;
    }

    .ant-select-selection-item {
      line-height: 41px;
    }

    .dir2 {
      font-size: 12px;
      line-height: 150%;
    }
    .note1 {
      padding: 0 16px;
    }

    .fs-16 {
      font-size: 16px;
    }
  }
}

@media (max-width: 842px) {
  .mobile-mt-12 {
    margin-top: 12px !important;
  }
  .mobile-mt-0 {
    margin-top: 0 !important;
  }
  .gas-velocity-to-flow-rate {
    .ant-table-wrapper {
      .ant-table.ant-table-bordered {
        .ant-table-tbody {
          > tr:nth-child(1),
          > tr:nth-child(2) {
            > td {
              padding: 16px 10px !important;
            }
          }
        }
      }
    }
  }

  .container-calculate-liquid-cv {
    padding: 0 16px;
    .calculate-liquid-cv {
      .input-calculate {
        // padding: 12px 24px;
        padding: 16px;

        .label {
          height: unset;
        }
        > .ant-row {
          row-gap: 12px !important;
        }

        .h-a-mb {
          height: auto !important;
        }

        .mt-16 {
          margin-top: 24px;
        }

        .ratio.justify-end {
          justify-content: start;
          padding-left: 0;
          height: 21px;
          margin: 12px 0;
        }
      }

      .note1 {
        padding-left: 16px !important;
        padding-right: 16px !important;
      }
    }

    .fs-10-mb {
      font-size: 10px !important;
    }

    .fs-12-mb {
      font-size: 12px !important;
    }

    .fs-10-mb {
      font-size: 10px !important;
    }

    .fs-12-mb {
      font-size: 12px !important;
    }
  }
}
