@import '../../styles/layout/config';

.linear-interpolation-container {
  .ml-1 {
    // margin-left: 10px;
  }
  .convert-icon {
    align-self: center;
    embed {
      margin: 0;
    }
    width: 24px;
    margin-left: 16px;
    margin-right: 16px;
  }
  .page-info {
    line-height: 41px;
    margin-bottom: 16px;
    > .ant-row {
      justify-content: space-between;
    }
    .info {
      align-items: center;
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      text-align: center;
    }
    .info-note {
      font-size: 12px;
      line-height: 150%;
    }
  }
  button {
    background: #4588c9;
    border-radius: 100px;
    font-weight: 500px;
    height: 41px;
    width: 145px;
    margin-bottom: 8px;
  }
  .ant-btn-primary:hover {
    background: #4588c9;
  }
  .input-chart-wrapper {
    flex-direction: row-reverse;

    & > div {
      background: #fff;
      border-radius: 12px;
      flex-shrink: 0;
    }

    .input-leader-points {
      .input-form {
        .ant-form-item {
          .input-label {
            text-align: center;
          }
        }
      }
      margin-left: 16px;
      flex-basis: calc(54% - 16px);
      padding: 24px;

      .title-container {
        margin-bottom: 16px;
        .title {
          display: inline-block;
          margin-right: 16px;
        }

        .error-message {
          display: inline-block;
        }
      }
    }

    .chart {
      flex-grow: 1;
      width: 30%;
      .default-chart-ja {
        height: 100%;
        background-repeat: no-repeat;
      }
      .default-chart-en {
        height: 100%;
        background-repeat: no-repeat;
      }
    }

    .input-label {
      border-radius: 80px;
      padding: 10px 40px;
    }

    .point {
      display: flex;
      width: 100%;

      .input-form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        width: 100%;
      }

      .point-title {
        font-size: 16px;
        flex-basis: 48px;
        line-height: 24px;
      }

      .point-1 {
        background: #c5e0b4;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #23262f;
      }

      .point-2 {
        background: #bdd7ee;
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 150%;
        color: #23262f;
      }
    }
  }

  .calc-wrapper {
    background: #fff;
    border: none;
    border-radius: 12px;
    margin-top: 12px;
    margin-bottom: 16px;
    padding: 24px;

    .info-wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0;

      > div {
        align-items: center;
        display: flex;
        justify-content: space-between;
        width: 100%;

        .ant-form-item {
          width: 100%;

          .formula-row-1 {
            margin-bottom: 16px;
            justify-content: space-between;
            align-items: center;
            .point-container {
              display: flex;
              align-self: flex-end;
              margin: 8px 0px;
            }
          }
        }

        &:first-child {
          .formula-title {
            color: #23262f;
            font-size: 16px;
          }

          .formula {
            display: flex;
            // display: grid;
            // grid-template-columns: auto auto;
            padding: 0;
            font-size: 16px;
            margin-right: 16px;
            > div {
              // align-items: center;
              // display: flex;
              // flex-direction: column;
              width: 125px;
              line-height: 52px;
              height: 52px;
              // padding: 0;

              > div {
                align-items: center;
                display: flex;
                flex-direction: row;
                justify-content: center;
                // gap: 10px;
                width: fit-content;
              }

              > div:first-child {
                border-bottom: 1px solid #000;
                height: 28px;
                padding: 0 0 4px;
              }

              > div:last-child {
                height: 28px;
                padding: 0;
              }
            }
          }

          .digit-input {
            align-items: center;
            display: flex;
            flex-direction: row;
            height: 41px;
            gap: 12px;
            padding: 0;

            input {
              min-width: 244px;
            }

            > label {
              font-size: 14px;
              line-height: 24px;
            }
          }
        }
      }
      .ant-input {
        min-width: 100px;
      }
    }
    .err-container {
      label {
        margin-right: 16px;
      }

      > .ant-row {
        align-items: baseline;
      }
    }
    .input-wrapper {
      display: flex;
      margin-top: 16px;
      flex-wrap: wrap;

      .flex {
        .ant-form-item-no-colon {
          background: #f4b183;
          border-radius: 80px;
          height: 41px;
        }

        .point-3 {
          color: #23262f;
          padding: 10px 20px;
          font-size: 14px;
          line-height: 150%;
          width: 100%;
        }

        .point-3-convert {
          width: 100%;
          padding: 10px 21.5px;
          font-size: 14px;
          line-height: 150%;
          color: #23262f;
        }
      }
    }
  }
  .flex {
    flex-flow: wrap;
  }
  form.en {
    .point {
      .point-title {
        font-size: 16px;
        flex-basis: 60px;
        line-height: 20px;
      }

      .point-1 {
        width: 150px !important;
      }

      .point-2 {
        width: 150px !important;
      }

      .input-label {
        padding: 10px 5px;
      }
    }

    .info-wrapper {
      .formula-title {
        width: unset !important;
        margin-right: 50px !important;
      }

      .formula {
        margin-right: 16px !important;
      }
      .ant-input {
        min-width: 100px;
      }
    }

    .input-wrapper {
      width: unset !important;

      .point-3 {
        text-align: center;
        width: 180px !important;
      }

      .point-3-convert {
        text-align: center;
        width: 180px;
        padding: 8px !important;
      }
    }
    .input-with-unit {
      margin: unset;
    }
    .ant-form-item-no-colon {
      justify-content: center;
    }
    @include media-sm {
      .input-chart-wrapper .input-leader-points .input-form .ant-form-item .input-label {
        width: 155px !important;
      }
      .input-with-unit .col-md {
        width: 120px;
        // width: unset;
        min-width: unset;
      }
      .ant-form-item-no-colon {
        justify-content: unset;
      }
    }
    @media screen and (max-width: 375px) {
      .col-md {
        max-width: 120px;
      }
    }
    // .linear-interpolation-container .calc-wrapper .input-wrapper .flex .point-3-convert
  }

  .layout-mb {
    flex: 1 1 1 33.3333%;
  }

  .title {
    color: #4588c9;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    // margin-bottom: 16px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;

    .ant-form-item-control {
      min-width: 320px;
    }

    .ant-form-item-row {
      gap: 16px;
      .ant-col {
        > label {
          min-height: 40px;
        }
      }
    }

    .ant-form-item-no-colon {
      &::after {
        margin: 0 !important;
      }
    }

    .ant-form-item-margin-offset {
      margin: 0 !important;
    }

    .point.ant-form-item-label {
      padding: 0;
    }
    .ant-input {
      text-align: right;
      &::placeholder {
        text-align: left;
      }
    }
  }
  .point {
    .ant-form-item {
      .ant-form-item-row {
        gap: 16px !important;
      }
    }
  }

  .input-with-unit {
    .unit {
      font-weight: 400;
      line-height: 41px;
      text-align: left;
      min-width: 28px;
      margin-left: 4px;
      display: inline-block;

      .label {
        display: inline-block;
        line-height: 21px;
      }

      .celsius {
        width: 14px;
      }

      .mpa {
        width: 28px;
      }
    }
  }

  .disable-field {
    background-color: #f5f5f5 !important;
    color: #23262f;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    padding: 10px 14px;

    cursor: unset;
  }
  #digit1,
  #digit2 {
    .ant-col:last-child {
      margin: 8px;
    }
  }

  .info-wrapper {
    .ant-form-item > .ant-row > .ant-col {
      margin: auto;
    }
  }
  @media screen and (max-width: 1430px) {
    .err-input-container {
      display: flex;
      width: 100%;
    }
    .col-md {
      width: 130px;
      .ant-input {
        min-width: 100%;
      }
    }
    .unit {
      margin: 0;
    }
    .convert-icon {
      width: 100%;
      // margin-right: 500px;
      embed {
        transform: rotate(90deg);
        margin-left: 390px;
      }
    }
    .err-container {
      .ant-row {
        align-items: baseline;
      }
      label {
        margin-right: 16px;
      }
    }
    .calc-wrapper {
      .info-wrapper {
        > div:last-child {
          justify-content: start;
        }
      }
      .input-wrapper {
        justify-content: unset;
        flex-direction: column;
        .input-with-unit:first-child {
          .ant-form-item .ant-row .ant-col {
            text-align: start;
          }
        }
        .flex:first-child .ant-form-item .ant-row {
          margin-right: 16px;
        }
        .flex .ant-form-item-no-colon {
          width: 230px;
          text-align: center;
        }
      }
    }
  }
  @media screen and (min-width: 588px) and (max-width: 645px) {
    .convert-icon {
      margin-left: unset;
      embed {
        margin-left: calc(100% - 240px) !important;
      }
    }
  }
  @media screen and (min-width: 420px) and (max-width: 587px) {
    .convert-icon {
      // transform: rotate(90deg);

      margin-left: unset;
      embed {
        margin-right: calc(100% - 140px) !important;
      }
    }
  }
  .calc-wrapper {
    .ant-form-item {
      .ant-form-item-row {
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 16px;
      }
    }
  }
  @media screen and (max-width: 700px) {
    .calc-wrapper {
      .ant-form-item {
        .ant-form-item-row {
          display: flex;
          grid-template-columns: unset;
          column-gap: unset;
          gap: 16px;
        }
      }
    }
  }
  @media screen and (min-width: 1401px) {
    .calc-wrapper {
      .ant-form-item {
        .ant-form-item-row {
          display: flex;
          grid-template-columns: unset;
          column-gap: unset;
          gap: 16px;
        }
      }
    }
  }

  @include media-sm {
    .err-input-container {
      > div {
        width: 100%;
      }
    }

    .input-chart-wrapper {
      flex-direction: column;
      .input-leader-points {
        padding: 16px;
      }
      .input-label {
        padding: 10px 19.5px;
      }
      .input-leader-points {
        margin-top: 16px;
        margin-left: 0;
        .input-form {
          .ant-form-item {
            .input-label {
              text-align: left;
            }
          }
        }
      }
    }
    .calc-wrapper {
      .title {
        margin-bottom: 24px;
      }
      .ant-form .input-wrapper {
        align-items: unset;
      }
      .info-wrapper > div:first-child .digit-input input {
        min-width: 144px;
      }
      .ant-form-item {
        .ant-form-item-row {
          display: flex;
          grid-template-columns: unset;
          column-gap: unset;
          gap: 12px;
        }
      }
    }
    .ant-form-item {
      .ant-form-item-control {
        min-width: 320px;
      }

      .ant-form-item-row {
        .ant-col {
          > label {
            min-height: unset;
          }
        }
      }
    }
    .input-leader-points .ant-form .ant-form-item .ant-form-item-label {
      font-size: 16px;
      line-height: 150%;
      height: 24px;
    }
    .point.point-2 {
      width: 116px;
    }
    .col-md {
      min-width: 144px;
      .ant-input {
        min-width: 100%;
      }
    }
    .unit {
      margin-left: 0;
    }

    .input-with-unit {
      .unit {
        min-width: unset;
        margin-right: unset;
        margin-left: unset;
        padding-right: unset;
        padding-left: unset;
      }
    }
    .chart {
      width: 100% !important;
      height: 316px;
    }
    .point-container .ant-col:nth-child(2) {
      width: 144px;
    }
    .convert-icon {
      width: 100%;
      embed {
        margin-left: 100px;
      }
    }
    div.ant-row {
      // row-gap: 12px !important;
      .point-container {
        margin: 0 !important;
      }
    }
    #digit1,
    #digit2 {
      display: flex;
      align-items: center;
    }
    .ant-form-item .ant-form-item-label {
      padding: 0 !important;
    }
    .input-leader-points {
      margin-left: 0;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1040px) {
    .input-chart-wrapper {
      .input-label {
        padding: 10px 30px;
      }
    }
    .convert-icon {
      margin: auto;
      width: 100%;
      embed {
        margin-left: 390px;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .ant-form-horizontal .ant-form-item-label {
      flex: 1 1 0 !important;
    }
  }
  @media screen and (min-width: 1401px) and (max-width: 1465px) {
    .en {
      .ant-form-item .ant-input {
        min-width: 280px;
      }
    }
  }
}
