@import '../../styles/layout/config';
.flow-calculation-container {
  .ant-select {
    .ant-select-selector {
      border-radius: 24px;
      height: 41px !important;

      .ant-select-selection-item {
        line-height: 41px;
      }
    }
  }

  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.85);
  }

  .input-result {
    background-color: #f5f5f5;
    pointer-events: none;
    border: unset;
    font-size: 16px;
    font-weight: 700;
    color: #23262f;
  }

  .result-container {
    .ant-form-item {
      label {
        color: #474b55;
      }
    }

    .ratio .title {
      width: 96px;
      color: #474b55;
      font-size: 14px;
      font-weight: 400;
    }

    .judgement {
      .title {
        width: 96px;
        color: black;
      }
    }

    .judgement,
    .ratio {
      display: flex;
      line-height: 36px;
      margin-right: 95px;
      margin-top: 16px;
      align-items: center;

      .ant-row {
        display: inline;
      }

      input {
        width: 300px;
      }

      .result-field {
        text-align: right;
        border: none;
      }
    }

    .input-group {
      margin: 0;
    }

    .dir {
      color: #474b55;
      font-weight: 400;
      font-size: 14px;
      margin-top: 16px;
    }
    .error-message-note {
      font-size: 16px;
      margin-left: 80px;
    }
  }

  .unit {
    font-family: 'Noto Sans JP';
  }

  .radio-button {
    .radio-label {
      height: 100%;
    }

    .ant-form-item-label {
      height: 24px !important;
    }

    .ant-form-item-control-input {
      min-height: 24px !important;
    }
  }

  .note1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 150%;
    margin: 16px 0;
  }

  .form-label {
    color: #474b55;
  }

  .unit {
    font-family: 'Noto Sans JP';
    font-size: 14px;
    color: #474b55;
    line-height: 41px;
  }

  @include media-md {
    .unit {
      padding-left: unset !important;
      margin-left: 8px;
      white-space: nowrap;
    }

    .input-container {
      .note1 {
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        display: flex;
        margin-top: 16px;
      }
      .col-md {
        min-width: 240px;
      }
    }

    .result-container {
      .result {
        margin-bottom: 12px;
      }

      .title {
        width: unset;
      }

      .judgement,
      .ratio {
        width: 100%;
        margin-top: 0;
        margin-bottom: 16px;
        align-items: center;
        .ant-row {
          display: flex;
        }
      }

      .result-table {
        .unit-table-container {
          .value-switching {
            margin: 18px 0 24px;
          }
        }
      }

      .dir {
        font-size: 12px;
        margin-top: 24px;
      }
      .error-message-note {
        margin-left: unset;
      }
    }
  }

  @include media-sm {
    .result-container {
      .judgement,
      .ratio {
        flex-direction: column;
        margin-right: 0;
        align-items: unset;
        .title {
          margin-bottom: 8px;
        }
      }
      .ant-input {
        width: 240px !important;
      }
    }
    .unit {
      margin-left: unset;
    }
    .judgement .title {
      margin-bottom: 8px;
    }
    .ant-radio-wrapper {
      margin-bottom: 8px;
    }
    .ant-form-item-no-colon {
      width: 100%;
      .dental-container {
        align-items: unset !important;
      }
    }

    .fl-liquid-cal {
      .ant-form-item-no-colon {
        width: 133px !important;
      }
    }

    .fl-gases-cal {
      .ant-form-item-no-colon {
        width: 153px !important;
      }
    }

    .fl-steam-cal {
      .ant-form-item-no-colon {
        width: 148px !important;
      }
    }
  }
  @include media-xl {
    .en {
      .flow-calculation-container .result-container {
        .judgement .title {
          margin-right: 16px;
          width: 133px;
        }
        .ratio .title {
          margin-right: 16px;
          width: 133px;
        }
      }
    }
  }
  @include media-sm {
    .en {
      .diff-pressure-container {
        .base-form-item .pressure-gases .ant-row.ant-form-item-row .ant-col > label {
          width: 100%;
        }
        .flow-calculation-container .result-container {
          .judgement .title {
            width: 100%;
          }
          .ratio .title {
            width: 100%;
          }
        }
      }
    }
  }
}
