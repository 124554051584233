@import '../../styles/common/color-variable';
@import '../../styles/layout/config';

.velocity-liquid-container {
  .result-field {
    border: none;
  }

  // .layout-mb {
  //   width: 33.333%;
  // }

  .input-container .lh-d .ant-form-item {
    line-height: 1.5714285714285714;
  }

  .note {
    font-size: 12px;
  }

  .note-mb {
    font-size: 16px;
  }

  .note1 {
    font-size: 10px;
    line-height: 150%;
    color: #23262f;
    margin-top: -8px;
  }

  .unit-table-container {
    .ant-col > .cell-null {
      border-bottom: 1px solid #f5f5f5;
    }
  }

  .title-result {
    color: #23262f;
    font-weight: 700;
    font-size: 16px;
    margin-right: 16px;
  }

  .error-result {
    color: #dd2f3e;
    font-weight: 700;
    font-size: 16px;
    // margin-left: 40px;
  }

  .input-container .ant-form-item-no-colon {
    margin-bottom: 12px;
    width: 129px;
  }

  .ant-input {
    text-align: end;
  }
  .form-label {
    width: 129px;
  }
}
@include media-sm {
  .velocity-liquid-container {
    .form-label {
      width: 100%;
    }
    .input-container .ant-form .ant-form-item .ant-row.ant-form-item-row .ant-col > label {
      width: 100%;
    }
  }
}
@media (max-width: 842px) {
  .velocity-liquid-container {
    .layout-mb {
      width: 100%;
    }

    .input-value {
      flex: 1 1 171px;
    }

    .pr-71 {
      padding-right: 71px !important;
    }

    .ant-form-item-no-colon {
      width: 100%;
    }

    .ant-form-item-label {
      text-align: start;
      display: block;
      max-width: 100%;
      margin-bottom: unset !important;
    }

    .ant-form-horizontal .ant-form-item-control {
      flex: 1 1 10px !important;
      min-width: 0;
    }

    .note {
      font-size: 10px;
    }

    .note-mb {
      margin-bottom: 0px;
      font-size: 12px;
    }

    .input-container .lh-d .ant-form-item {
      margin-bottom: 0;
    }

    .d-none-mb {
      display: none;
    }

    .base-form-item:first-child {
      margin-bottom: unset;
    }
    label {
      width: 133px;
    }
  }
}
