@include media-xl {
  .input-container {
    .base-form-item {
      .flex {
        .ant-form-item-no-colon {
          flex-shrink: 0;
        }

        .base-form-note {
          flex-grow: 1;
          flex-basis: 342px;
        }
      }

      .ant-form-item {
        margin-bottom: $form-item-margin-pc;

        // &.input-mb-unset {
        //   margin-bottom: 0 !important;
        // }
        .base-form-note {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
          color: #545454;
          margin-top: 8px;

          &.base-form-note-group {
            margin-top: 16px;
          }
        }
      }

      .col-md {
        min-width: $input-min-width-pc;
        // width: 30%;
        // min width = 320 + padding left & right 8px
        // min-width: $input-min-width-pc;
      }

      .flex.flex-wrap {
        .base-form-note {
          font-weight: 400;
          font-size: 10px;
          line-height: 150%;
          color: #545454;

          &.base-form-note-group {
            font-size: 16px;
            margin-bottom: 16px;
          }
        }
      }

      .ant-form-item-control-input {
        min-height: 24px;
      }
    }
  }
}

@include media-md {
  .input-container {
    #primary-title {
      margin-bottom: 24px;
    }
    .base-form {
      .base-form-item {
        margin-bottom: 24px;

        .ant-form-item-label {
          display: inline-flex;
        }

        .ant-form-item {
          // margin-bottom: calc($form-item-margin-mb * 2);

          // &.input-group {
          //   .ant-input-group {
          //     & :last-child {
          //       margin-bottom: 0 !important;
          //     }
          //   }
          // }

          &.margin-top-16-12 {
            .base-form-note {
              margin-top: 12px;
            }
          }
          &.margin-top-8-8 {
            .base-form-note {
              margin-top: 8px;
            }
          }
        }
      }
      .base-form-item:last-child {
        margin-bottom: 0;
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      }
    }
  }
}

// @include media-tablet {
//   .input-container {
//     .base-form-item {
//       .ant-form-item {
//         &.input-group {
//           .ant-input-group {
//             .base-form-input {
//               margin-bottom: $form-item-margin-pc;
//             }
//           }
//         }
//       }
//     }
//   }
// }
@include media-sm {
  .input-container {
    .base-form {
      .col-md {
        width: 30%;
        // min width = 240 + padding left & right 8px
        min-width: $input-min-width-mb;
      }

      .base-form-item {
        .ant-form-item-label {
          height: auto;

          // .ant-form-item-no-colon {
          //   width: 100%;
          // }
        }

        .radio-button {
          margin-bottom: 12px;

          .ant-form-item-control-input {
            min-height: auto;
          }
        }

        .row-gap-mb {
          row-gap: 12px !important;
        }

        .text-under-radiogroup {
          margin: 0;
        }

        .ant-radio-group {
          line-height: 150%;
        }

        .ant-radio-wrapper {
          line-height: 150%;
        }

        .ant-form-item {
          line-height: 150%;

          .base-form-note {
            white-space: pre-line;
            color: #474b55;
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;
            margin-top: 8px;

            &.base-form-note-group {
              font-size: 12px;
              margin-top: 12px;
            }
          }
        }

        .flex.flex-wrap {
          .base-form-note {
            white-space: pre-line;
            color: #474b55;
            font-weight: 400;
            font-size: 10px;
            line-height: 150%;

            &.base-form-note-group {
              font-size: 12px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
