@import '../../styles/layout/config';
.diff-pressure-container {
  .ant-select {
    .ant-select-selector {
      border-radius: 24px;
      height: 41px !important;

      .ant-select-selection-item {
        line-height: 36px;

        .ant-select-selection-item {
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.85);
  }

  .ant-col.ant-form-item-label {
    .form-label {
      line-height: 150%;
      // margin-bottom: 8px;
    }
  }

  .input-result {
    background-color: #f5f5f5;
    pointer-events: none;
    border: unset;
    font-size: 16px;
    font-weight: 700;
    color: #23262f;
  }

  .result-container {
    .judgement,
    .ratio {
      align-items: center;
      display: inline-block;
      line-height: 36px;
    }

    .result > .title {
      margin-bottom: 16px;
    }

    .input-group {
      margin: 0;
    }
    .include-result {
      .include-result__col {
        margin-bottom: 16px;
      }
    }
  }
  .value-switching {
    margin: 0 0 16px 0;
  }
  .dir {
    color: #545454;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
  }

  .note1 {
    white-space: pre-wrap;
    max-width: 300px;
    margin: 16px 0;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
    color: #545454;
  }

  input::placeholder {
    color: #a9a9a9;
    font-size: 14px;
    font-family: 'Noto Sans JP';
  }

  .unit {
    font-size: 14px;
    color: #474b55;
  }

  .unit2 {
    line-height: 41px;
    font-size: 14px;
    color: #474b55;
  }

  .unit1 {
    line-height: 41px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
  }
  .base-form-item .gf-value {
    margin-bottom: 0;
  }
  .base-form .ant-form-item .base-form-note {
    margin-top: 8px;
    font-weight: 400;
    font-size: 10px;
    line-height: 150%;
  }
  .base-form-item .ant-row.ant-form-item-row .ant-col > label {
    align-items: center;
    width: 153px;
    align-items: center;
  }
  .include-result {
    width: 100%;
  }
  .dental-container {
    align-items: center;
  }
  @include media-sm {
    .input-container .base-form-item .ant-form-item-no-colon {
      width: 100%;
    }
    .input-result {
      width: 240px !important;
    }
    .dental-container {
      flex-direction: column;
      align-items: unset !important;
      margin-left: 8px;
      .unit {
        width: 100% !important;
        margin-left: 0;
      }
    }

    .include-result {
      width: 100%;
      .include-result__col {
        width: 100%;
      }
    }
    .result-container {
      .judgement,
      .ratio {
        line-height: 24px;
        margin-bottom: 12px;
      }

      .result > .title {
        margin-bottom: 24px;
      }
      .include-result {
        .include-result__col {
          margin-bottom: 12px;
        }
      }

      .ant-row:first-child {
        margin-bottom: 12px;
      }

      .unit-table-container {
        .ant-row:first-child {
          margin-bottom: auto;
        }
      }
    }
  }
  @include media-md {
    .input-container {
      .ant-form-item-no-colon {
        align-items: flex-start;
      }
    }
  }
  @include media-md {
    .col {
      flex-basis: 25%;
      max-width: 25%;
    }
    .title {
      width: unset;
    }
    .ant-form .ant-form-item .ant-form-item-label {
      font-family: 'Noto Sans JP';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
    }
    .base-form-item:nth-last-child(1) {
      margin-bottom: 0 !important;
    }
    .base-form-item:first-child {
      margin-bottom: 24px !important;
    }
    .base-form .base-form-item {
      margin-bottom: 24px;
      .ant-row .ant-col:nth-child(2) {
        .col-md:nth-last-child(1) {
          margin: 0;
        }
      }
    }
  }

  @include media-xl {
    .base-form-item:nth-last-child(1) {
      margin-bottom: 0 !important;
    }
    .base-form-item:first-child {
      margin-bottom: 16px;
    }
    .text-under-radiogroup {
      margin-bottom: 16px;
    }
    .ant-radio-wrapper {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }
  }
  @include media-l {
    .include-result {
      width: 100%;
      .include-result__col {
        width: 100%;
        .flex-end {
          justify-content: unset;
        }
      }
    }
  }
  .fluid-state > .ant-row > .ant-col > .ant-form-item-no-colon {
    height: 0 !important;
  }

  .fluid-state .fluid-state-checkbox {
    font-weight: 400;
    font-size: 14px;
    color: #23262f;
  }

  .fluid-state {
    .ant-form-item-label {
      height: 41px !important;
      .ant-form-item-no-colon {
        align-items: center;
      }
    }

    .ant-form-item-control-input {
      min-height: 24px !important;

      .ant-radio-group {
        display: flex;
        justify-content: space-between;
        width: 240px;
      }
    }
  }

  .ant-select-selection-item {
    color: rgba(0, 0, 0, 0.85);
  }
  .note1 {
    max-width: 240px;
  }
  @media screen and (max-width: 1212px) {
    .include-result {
      flex-direction: column;
    }
    .ant-row {
      align-content: unset !important;
    }
  }
}
