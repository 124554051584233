// define all red color
$red: #ff5757;

// define all blue color
$blue: #57aeff;

// define all yellow color
$yellow: #ffd057;

// define all green color
$green: #5acf66;

// define all black color
$black: #000;

// define all black1 color
$black1: #23262f;

// define all black2 color
$black2: #545454;

// define all grey1 color
$grey1: #474b55;

// define all white color
$white: #fff;

// define all neutral6 color
$neutral6: #bfbfbf;

// define all neutral4 color
$neutral4: #f0f0f0;

// define all Character/Primary  color
/* stylelint-disable-next-line color-function-notation */
$text-primary: rgba(0, 0, 0, 85%);

// define all danger  color
$text-danger: #dd2f3e;

// define all  brand/Secondary  color
$brand-secondary: #285fa0;
