.unit-table-container {
  .first-class {
    > .ant-row > .ant-col:first-child {
      background-color: #f5f5f5;
    }
  }

  .des {
    font-weight: 400;
    font-size: 16px;
    color: #545454;
    margin-bottom: 16px;
    margin-top: -8px;
  }
  .ant-table-wrapper {
    .ant-table-tbody {
      > tr {
        > td {
          padding: 15px !important;
          border-bottom: 1px solid #e5e5e5;
          border-left: 1px solid #e5e5e5;
          border-right: 1px solid #e5e5e5;
        }
      }
    }
    .ant-table.ant-table-bordered > .ant-table-container {
      border-inline-start: unset !important;
    }
  }

  .unit-table {
    tr:nth-child(even) {
      .text-tr {
        background: #f5f5f5 !important;
      }

      .result-value {
        background: #d6ecff !important;
      }
    }

    tr:nth-child(odd) {
      .text-tr {
        background: #ffffff !important;
      }
      .result-value {
        background: #e7f2ff !important;
      }
    }
    &.table1 {
      &.has-table2 {
        tr:last-child {
          > td {
            padding-bottom: 14px !important;
          }
        }
      }
      .ant-table-content {
        table {
          border-top: 0 !important;
        }
      }
    }
    &.table2 {
      .ant-table-content {
        table {
          border-top: 0 !important;
        }
      }
    }
    thead .ant-table-cell {
      background: #f2f8ff !important;
      text-align: center !important;
      font-size: 16px;
      color: #4588c9 !important;
    }
  }

  .title-none thead {
    display: none;
  }

  .hr {
    border-top: 1px solid #a3a3a3;
  }

  table {
    border-radius: 0;
  }

  table,
  th,
  td {
    border: 1px solid re5e5e5ed;
  }

  .ant-table-wrapper .ant-table-container {
    border-start-start-radius: 0;
    border-start-end-radius: 0;
  }

  .title-table {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    color: #4588c9;
    font-family: 'Noto Sans JP';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
  }

  .text-center {
    text-align: center;
  }

  .header-table {
    background: #f2f8ff;
    color: #285fa0;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Noto Sans JP';
  }

  .cell-null {
    width: 112px;
    background: #f5f5f5;
    height: 56px;
  }

  .header-tr {
    padding: 16px 0;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
    font-family: inherit;
  }

  .header-tr:nth-child(1) {
    width: 40%;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-left: 1px solid #e5e5e5;
    border-color: #e5e5e5;
  }

  .header-tr:nth-child(2) {
    width: 30%;
    border: 1px solid #e5e5e5;
    border-color: #e5e5e5;
  }

  .header-tr:nth-child(3) {
    width: 30%;
    border-top: 1px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
    border-color: #e5e5e5;
  }

  @media (max-width: 842px) {
    .ant-col {
      flex: unset !important;
      width: 100%;
    }

    .title-table.text-center {
      padding: 10px 16px;
      font-size: 14px;
      border-right: 1px solid #e5e5e5;
      border-left: 1px solid #e5e5e5;
    }

    .cell-null {
      display: none;
    }

    .text-tr {
      font-size: 12px;
      // min-width: 103.6667px;
    }

    .des {
      margin-top: 0;
      margin-bottom: 24px;
      font-size: 12px !important;
    }

    .result-value {
      // min-width: 103.6667px;
    }
  }
}
